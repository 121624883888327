<template>
	<v-menu
		ref="datepicker"
		v-model="datepicker"
		:close-on-content-click="false"
		transition="scale-transition"
		offset-y
		max-width="290px"
		min-width="auto"
		:nudge-left="nudgeLeft"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
				:id="id"
				:label="label"
				:loading="loading"
				clearable
				:disabled="disabled"
				outlined
				class="pt-0"
				:style="inlineStyle"
				v-model="dateFormatted"
				:placeholder="btxPlaceholder"
				hide-details
				prepend-inner-icon="mdi-calendar"
				v-bind="attrs"
				v-on:blur="date = parseDateArray(dateFormatted)"
				v-on="on"
				v-on:click:clear="
					date = [];
					$emit('click:clear', true);
				"
				@input="$emit('input', $event)"
			></v-text-field>
		</template>
		<v-date-picker v-model="date" no-title range :min="minDate" :max="maxDate"></v-date-picker>
		<!-- <v-date-picker
			v-model="date"
			no-title
			range
			:min="minDate"
			:allowed-dates="disablePastDates"
			:max="maxDate"
		></v-date-picker> -->
	</v-menu>
</template>

<script>
import { last, head } from "lodash";
export default {
	name: "date-range-picker",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: [Array, String],
			default: null,
		},
		inlineStyle: {
			type: Object,
			default: () => {},
		},
		minDate: {
			type: [Date, String],
			default: null,
		},
		maxDate: {
			type: [Date, String],
			default: null,
		},
		placeholder: {
			type: String,
			default: "Date",
		},
		id: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		clear: {
			type: Number,
			default: 0,
		},
		/* disablePastFrom: {
			type: String,
		}, */
		nudgeLeft: {
			type: [String, Number],
			default: 0,
		},
	},
	data() {
		return {
			date: [],
			datepicker: false,
			dateFormatted: null,
			stopEmit: false,
		};
	},
	watch: {
		/* minDate was not working so commenting */
		// minDate() {
		// 	this.date = [];
		// },
		maxDate() {
			this.date = [];
		},
		clear(param) {
			if (param) {
				this.date = null;
				this.$emit("clearDates", false);
			}
		},
		date() {
			this.dateFormatted = this.formatDate(this.date);
			this.$emit("input", this.date);
			this.$emit("change", this.date);
		},
		value: {
			deep: true,
			handler(param) {
				/** Also clear the state */
				this.date = param;
			},
		},
	},
	methods: {
		formatDate(dates) {
			if (!dates) return null;
			const startDate = head(dates);
			const endDate = last(dates);
			const data = this.splitDate(startDate) + " - " + this.splitDate(endDate);
			return startDate && endDate ? data : null;
		},
		splitDate(date) {
			if (!date) return null;
			const [year, month, day] = date.split("-");
			return `${day}/${month}/${year}`;
		},
		parseDateArray(dates) {
			if (!dates) return [];
			const dateArray = dates.split(" - ");
			const startDate = head(dateArray);
			const endDate = last(dateArray);
			return [this.parseDate(startDate), this.parseDate(endDate)];
		},
		parseDate(date) {
			if (date && date != "null") {
				const [day, month, year] = date.split("/");
				return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
			}
			return null;
		},
		/* disablePastDates(val) {
			return this.disablePastFrom
				? val >= new Date(this.disablePastFrom).toISOString().substr(0, 10)
				: new Date();
		}, */
	},
	computed: {
		btxPlaceholder() {
			return this.placeholder + " [DD/MM/YYYY] - [DD/MM/YYYY]";
		},
	},
	mounted() {
		this.date = this.value;
	},
};
</script>
