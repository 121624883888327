<template>
	<Dialog :dialog="updatedDialog" :dialog-width="800" @close="$emit('close')">
		<template v-slot:title>Update Domain </template>
		<template v-slot:body>
			<v-sheet id="lead">
				<v-form
					ref="extendCheckoutForm"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="update_or_create"
				>
					<div class="">
						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label class="input-label">Service Provider</label>
							</v-col>
							<v-col lg="9" class="my-auto py-0 position-relative">
								<v-autocomplete
									:items="serverProviders"
									item-text="text"
									item-value="value"
									hide-details
									outlined
									placeholder="Service Provider"
									v-model="domain.service_provider"
									:class="{
										required: !domain.service_provider,
									}"
									:rules="[vrules.required(domain.service_provider, 'Service Provider')]"
								>
								</v-autocomplete>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label for="cost" class="input-label">Cost</label>
							</v-col>
							<v-col lg="9" cols="12" class="my-auto py-0">
								<!-- {{ domain.cost }} -->
								<TextInput
									hide-details
									clearable
									:disabled="pageLoading"
									:loading="pageLoading"
									id="cost"
									placeholder="Cost Price"
									v-model="domain.cost"
									:class="{
										required: !domain.cost,
									}"
									@keydown="amountInput($event, 'cost')"
									:rules="[vrules.required(domain.cost, 'Cost')]"
								>
								</TextInput>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label for="sell_price" class="input-label">Sell Price</label>
							</v-col>
							<v-col lg="9" cols="12" class="my-auto py-0">
								<TextInput
									hide-details
									clearable
									id="sell_price"
									:disabled="pageLoading"
									:loading="pageLoading"
									placeholder="Cost Price"
									v-model="domain.sales_price"
									:class="{
										required: !domain.sales_price,
									}"
									@keydown="amountInput($event, 'sales_price')"
									:rules="[vrules.required(domain.cost, 'Sell Price')]"
								>
								</TextInput>

								<!-- <NumberInput
									hide-details
									clearable
									id="sell_price"
									:disabled="pageLoading"
									:loading="pageLoading"
									placeholder="Sell Price"
									v-model="domain.sales_price"
									:class="{
										required: !domain.sales_price,
									}"
									
									:rules="[vrules.required(domain.sales_price, 'Sell Price')]"
								>
								</NumberInput> -->
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label class="input-label">Start Date</label>
							</v-col>
							<v-col lg="9" cols="12" class="my-auto py-0">
								<DatePicker
									:key="+new Date()"
									hide-details
									clearable
									:disabled="pageLoading"
									:loading="pageLoading"
									id="start-date"
									placeholder="Start Date"
									v-model="domain.start_date"
									:class="{
										required: !domain.start_date,
									}"
									:rules="[vrules.required(domain.start_date, 'Start Date')]"
									:max-date="domain.end_date"
								>
								</DatePicker>
								<!-- @change="domain.end_date = null"
								:max-date="domain.end_date" -->
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label class="input-label">End Date</label>
							</v-col>
							<v-col lg="9" cols="12" class="my-auto py-0">
								<DatePicker
									:key="+new Date() + 1"
									hide-details
									clearable
									:disabled="pageLoading"
									:loading="pageLoading"
									id="start-date"
									placeholder="End Date"
									v-model="domain.end_date"
									:disablePastFrom="disablePastDate"
									:class="{
										required: !domain.end_date,
									}"
									:rules="[vrules.required(domain.end_date, 'End Date')]"
									:min-date="domain.start_date"
								></DatePicker>
							</v-col>
						</v-row>

						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="py-0">
								<label class="input-label">Remark </label>
							</v-col>

							<v-col lg="9" class="my-auto py-0">
								<TextAreaInput
									:rows="4"
									dense
									ref="comment"
									hide-details
									v-model="domain.remark"
									placeholder="Enter Remark..."
									:disabled="pageLoading"
									:loading="pageLoading"
									:word-limit="200"
								></TextAreaInput>
							</v-col>
						</v-row>
					</div>
				</v-form>
			</v-sheet>
		</template>
		<template v-slot:action>
			<v-btn
				depressed
				:disabled="pageLoading || !formValid"
				tile
				color="blue darken-4"
				class="text-white my-auto mt-3"
				v-on:click="updateDomain"
				>Save</v-btn
			>
			<v-btn
				depressed
				tile
				:disabled="pageLoading"
				class="my-auto mt-3"
				v-on:click="$emit('close', true)"
			>
				Close
			</v-btn>
		</template>
	</Dialog>
</template>
<script>
// import { mapGetters } from "vuex";
import { GET, PUT } from "@/core/services/store/request.module";
import Dialog from "@/view/components/Dialog";
import DatePicker from "@/view/components/DatePicker";
import TextAreaInput from "@/view/components/TextAreaInput";
/* import NumberInput from "@/view/components/NumberInput"; */
import TextInput from "@/view/components/TextInput";
// import MomentJS from "moment-timezone";
// import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
// import { HostingEventBus } from "@/core/lib/hosting.lib";
import MainMixin from "@/core/mixins/main.mixin.js";
import { filter, toString } from "lodash";

export default {
	mixins: [MainMixin],
	name: "update-domain",
	title: "update-domain",
	props: {
		updatedDialog: {
			type: Boolean,
			default: false,
		},
		detailId: {
			type: [Number, String],
			default: null,
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			domain: {
				service_provider: null,
				cost: null,
				sales_price: null,
				start_date: null,
				end_Date: null,
				remark: null,
			},
			serverProviders: [],
			disablePastDate: null,
		};
	},

	components: {
		Dialog,
		/* NumberInput, */
		TextAreaInput,
		TextInput,
		DatePicker,
	},
	watch: {
		"domain.start_date": {
			handler: function (param) {
				this.disablePastDate = this.calculate_next_day(param);
			},
		},
	},
	mounted() {
		this.getServiceProvider();
		if (this.detailId) this.getDomain();
	},
	methods: {
		amountInput(event, type) {
			let charCode = event.keyCode || event.which;
			let value = toString(this.domain[type]);

			if (charCode == 8 /* backspace */ || charCode == 9 /* tab */) {
				return false;
			}

			if (
				(charCode == 110 ||
					(charCode >= 48 && charCode <= 57) ||
					(charCode >= 96 && charCode <= 105)) &&
				value.length < 12
			) {
				return false;
			} else {
				event.preventDefault();
			}
		},
		updateDomain() {
			const payload = {
				id: this.detailId,
				service_provider: this.domain.service_provider,
				cost: this.domain.cost,
				sales_price: this.domain.sales_price,
				end_date: this.domain.end_date,
				start_date: this.domain.start_date,
				remark: this.domain.remark,
			};

			const _this = this;
			_this.$store
				.dispatch(PUT, { url: `domain-details/edit`, data: { ...payload } })
				.then((data) => {
					console.log({ data });
					this.$emit("success");
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getServiceProvider() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: `service-provider-dropdown`,
				})
				.then((data) => {
					this.serverProviders = filter(data, (row) => row.is_domain == 1);
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
		getDomain() {
			this.pageLoading = true;
			const _this = this;
			_this.$store
				.dispatch(GET, { url: `domain-details/${this.detailId}` })
				.then((data) => {
					this.domain.cost = data.cost;
					this.domain.sales_price = data.sales_price;
					this.domain.start_date = data.start_date;
					this.domain.end_date = data.end_date;
					this.domain.remark = data.remark;
					this.domain.service_provider = data.service_provider;
					// setTimeout(() => {
					// 	_this.ssl.end_date = data.end_date;
					// }, 100)
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
	},

	// computed: {
	// 	...mapGetters(["errors"]),
	// },
};
</script>
