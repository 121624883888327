<template>
	<!-- <v-sheet class="recurring-main-listing position-relative"> -->
	<!-- <v-sheet class="recurring-main-listing position-relative"> -->
	<div>
		<v-layout class="flex-wrap">
			<div class="pt-3 d-flex align-start justify-space-between w-100">
				<v-flex class="custom d-flex align-items-center">
					<span class="me-3">
						<ImageTemplate
							style="max-width: 45px; height: 45px; box-shadow: 0px 0px 1px 0px #888"
							src="https://images.unsplash.com/photo-1603415526960-f7e0328c63b1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
							circle
						></ImageTemplate>
					</span>
					<div class="d-flex align-center">
						<p class="m-0" style="font-size: 18px; font-weight: 700">Mark Send</p>
					</div>
					<v-select
						v-model="status"
						solo
						class="listing-select"
						hide-details
						:items="domain_status_list"
					>
						<template v-slot:item="{ item }">
							<v-list-item-content>
								<v-list-item-title v-text="item.text"></v-list-item-title>
								<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
							</v-list-item-content>
							<v-list-item-action>
								<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
									<span>{{ item.status_count }}</span>
								</v-avatar>
							</v-list-item-action>
						</template>
					</v-select>
				</v-flex>
				<v-spacer></v-spacer>
				<div class="d-flex align-start">
					<DateRangePicker
						hide-details
						hide-top-margin
						clearable
						class="mr-2"
						style="width: 300px"
						placeholder="Date Range"
						v-model="date_range"
					></DateRangePicker>
					<div class="">
						<v-btn
							depressed
							color="white"
							class="red darken-4 ml-2"
							tile
							text
							small
							min-height="30"
							style="min-width: 0px !important"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>

					<v-btn depressed color="blue darken-4 white--text" tile min-height="30" class="ml-2">
						<v-icon color="white">mdi-magnify</v-icon>
					</v-btn>
				</div>
			</div>
		</v-layout>

		<div style="height: calc(100vh - 125px); overflow-x: hidden; overflow-y: auto">
			<!-- <div class="team-preview">
				<div class="view-wrapper"> -->
			<v-row dense>
				<v-col md="3">
					<v-card class="card_shadow" color="blue lighten-5" style="height: 162px">
						<v-card-title class="pb-1 pt-3 h3 text-primary fw-700">Projects</v-card-title>
						<v-card-text>
							<div class="mt-1 mx-1">
								<div class="team_member d-flex justify-content-between align-items-center">
									<div class="font-level-3-bold team-span">
										<!-- <span class="blue--text h4 fw-600">23</span> -->
										<v-avatar class="white--text" size="30" color="blue"> 23 </v-avatar>
									</div>
									<div class="font-team fw-600 h4 blue--text">
										All
										<sup>
											<v-icon color="blue darken-4" class="cursor-pointer" size="17">mdi-open-in-new</v-icon>
										</sup>
									</div>
								</div>
								<div class="team_member d-flex mt-2 justify-content-between align-items-center">
									<div class="font-level-3-bold team-span">
										<!-- <span class="cyan--text h4 fw-600">23</span> -->
										<v-avatar class="white--text" size="30" color="cyan"> 23 </v-avatar>
									</div>
									<div class="font-team fw-600 h4 cyan--text">
										New
										<sup>
											<v-icon color="blue darken-4" class="cursor-pointer" size="17">mdi-open-in-new</v-icon>
										</sup>
									</div>
								</div>
								<div class="team_member mt-2 d-flex justify-content-between align-items-center">
									<div class="font-level-3-bold team-span">
										<!-- <span class="red--text h4 fw-600">23</span> -->
										<v-avatar class="white--text" size="30" color="red"> 23 </v-avatar>
									</div>
									<div class="font-team fw-600 h4 red--text">
										Cancelled
										<sup>
											<v-icon color="blue darken-4" class="cursor-pointer" size="17">mdi-open-in-new</v-icon>
										</sup>
									</div>
								</div>
							</div>
						</v-card-text>
						<!-- <div class="fw-500 chip_part ml-3" style="margin-top: 55px">
								<a href="#" style="text-decoration: underline">All Projects</a>
								<div>
									<span class="text-black" style="font-size: 17px"></span>
								</div>
							</div> -->
					</v-card>
				</v-col>
				<v-col md="3">
					<v-card
						class="card_shadow"
						color="green lighten-5"
						style="
							border-top-right-radius: 0 !important;
							border-bottom-right-radius: 0 !important;
							height: 162px;
						"
					>
						<v-card-title class="pt-3 pb-2 d-flex justify-content-between align-items-center">
							<div class="font-team h3 fw-700 green--text">Green</div>
							<div class="font-level-3-bold team-span">
								<span class="green--text fw-700 h3">
									<v-icon color="green">mdi-arrow-top-right </v-icon>
									80%
								</span>
							</div>
						</v-card-title>
						<v-card-text>
							<div class="mt-1 mx-1">
								<div class="fw-700 price-section green--text">
									<p class="h3 fw-700 m-0">7</p>
								</div>
								<div class="fw-500 chip_part">
									<a href="#" style="text-decoration: underline">View net earning</a>
									<div>
										<v-progress-circular
											:size="size"
											:width="width"
											:rotate="rotate"
											:value="currentPercentage"
											:color="color"
											:style="{ fontWeight: '800' }"
										>
											<span class="text-black" style="font-size: 17px">75%</span>
										</v-progress-circular>
									</div>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col md="3">
					<v-card
						class="card_shadow"
						color="red lighten-5"
						style="
							height: 162px;
							border-top-left-radius: 0 !important;
							border-bottom-left-radius: 0 !important;
						"
					>
						<v-card-title class="pt-3 pb-2 d-flex justify-content-between align-items-center">
							<div class="font-team h3 fw-700 text-danger">Red</div>
							<div class="font-level-3-bold team-span">
								<span class="text-danger" style="font-size: 20px; font-weight: 800">
									<v-icon color="red">mdi-arrow-bottom-left </v-icon>
									<!-- {{ currentPercentageRed }}% -->
									20%
								</span>
							</div>
						</v-card-title>
						<v-card-text>
							<div class="mt-1 mx-1">
								<div class="fw-700 price-section text-danger">
									<p class="h3 fw-700 m-0">10</p>
								</div>
								<div class="fw-500 chip_part">
									<a href="#" style="text-decoration: underline">View net earning</a>
									<div>
										<v-progress-circular
											:size="size"
											:width="width"
											:rotate="rotate"
											:value="currentPercentageRed"
											color="red"
											:style="{ fontWeight: '800' }"
										>
											<span class="text-black" style="font-size: 17px">{{ currentPercentageRed }}%</span>
										</v-progress-circular>
									</div>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col md="3">
					<v-card class="card_shadow" color="deep-purple lighten-5" style="height: 162px">
						<v-card-title class="pt-3 pb-2 h3 fw-700 text-info">Average Rank</v-card-title>
						<v-card-text>
							<div class="mt-1 mx-1">
								<div class="team_member d-flex justify-content-between align-items-center">
									<div class="font-team fw-600 h4 blue--text">Top 5</div>
									<div class="font-level-3-bold team-span">
										<!-- <span class="blue--text h4 fw-600">23</span> -->
										<v-avatar class="white--text" size="30" color="blue"> 23 </v-avatar>
									</div>
								</div>
								<div class="team_member d-flex mt-2 justify-content-between align-items-center">
									<div class="font-team fw-600 h4 cyan--text">Top 10</div>
									<div class="font-level-3-bold team-span">
										<v-avatar class="white--text" size="30" color="cyan"> 23 </v-avatar>
									</div>
								</div>
								<div class="team_member mt-2 d-flex justify-content-between align-items-center">
									<div class="font-team fw-600 h4 red--text">Top 20</div>
									<div class="font-level-3-bold team-span">
										<v-avatar class="white--text" size="30" color="red"> 23 </v-avatar>
									</div>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<!-- </div>
			</div> -->

			<v-row class="mt-1">
				<v-layout>
					<v-col md="3">
						<v-card class="mx-auto" style="border: 0.5px solid #90caf9">
							<v-card-actions>
								<v-list-item class="grow px-0">
									<v-list-item-content>
										<v-list-item-title
											class="custom-form-label me-0"
											style="font-weight: 600; font-size: 18px"
										>
											<VAvatar :size="55" rounded color="blue lighten-5" variant="tonal" class="me-3">
												<v-icon size="34" color="blue darken-4" dark>task</v-icon>
											</VAvatar>
											TOTAL TASK
										</v-list-item-title>
									</v-list-item-content>
									<div align="center" justify="end">
										<span class="subheading">
											<v-avatar color="#0D47A1" size="35">
												<span class="white--text text-h5">48</span>
											</v-avatar>
										</span>
									</div>
								</v-list-item>
							</v-card-actions>
						</v-card>
					</v-col>
					<v-col md="3">
						<v-card class="mx-auto" style="border: 0.5px solid #ef9a9a">
							<v-card-actions>
								<v-list-item class="grow px-0">
									<v-list-item-content>
										<v-list-item-title class="custom-form-label" style="font-weight: 600; font-size: 16px">
											<VAvatar :size="55" rounded color="red lighten-5" variant="tonal" class="me-3">
												<v-icon size="34" color="red" dark>mdi-calendar</v-icon>
											</VAvatar>
											Due</v-list-item-title
										>
									</v-list-item-content>
									<div align="center" justify="end">
										<span class="subheading">
											<v-avatar color="red" size="35">
												<span class="white--text text-h5">48</span>
											</v-avatar>
											<!-- <v-chip color="red" style="font-size: 18px; color: white; font-weight: 600">5.00</v-chip> -->
										</span>
									</div>
								</v-list-item>
							</v-card-actions>
						</v-card>
					</v-col>
					<v-col md="3">
						<v-card class="mx-auto" style="border: 0.5px solid #81c784">
							<v-card-actions>
								<v-list-item class="grow px-0">
									<v-list-item-content>
										<v-list-item-title class="custom-form-label" style="font-weight: 600; font-size: 16px">
											<VAvatar :size="55" rounded color="green lighten-5" variant="tonal" class="me-3">
												<v-icon size="34" color="green" dark>add_task</v-icon>
											</VAvatar>
											Completed
										</v-list-item-title>
									</v-list-item-content>

									<div align="center" justify="end">
										<span class="subheading">
											<!-- <v-chip color="green" style="font-size: 18px; color: white; font-weight: 600">
											45.00</v-chip> -->
											<v-avatar color="green" size="35">
												<span class="white--text text-h5">48</span>
											</v-avatar>
										</span>
									</div>
								</v-list-item>
							</v-card-actions>
						</v-card>
					</v-col>
					<v-col md="3">
						<v-card class="mx-auto" style="border: 0.5px solid orange">
							<v-card-actions>
								<v-list-item class="grow px-0">
									<v-list-item-content>
										<v-list-item-title class="custom-form-label" style="font-weight: 600; font-size: 16px">
											<VAvatar :size="55" rounded color="orange lighten-5" variant="tonal" class="me-3">
												<v-icon size="34" color="orange" dark>mdi-calendar</v-icon>
											</VAvatar>
											Expiring In 3 Month</v-list-item-title
										>
									</v-list-item-content>

									<div align="center" justify="end">
										<span class="subheading">
											<v-avatar color="orange" size="35">
												<span class="white--text text-h5">48</span>
											</v-avatar>
										</span>
									</div>
								</v-list-item>
							</v-card-actions>
						</v-card>
					</v-col>
				</v-layout>
			</v-row>

			<v-row class="mt-1">
				<v-col md="3">
					<v-card outlined>
						<v-card-title class="pb-0 pt-3 fw-600">Highest Worked Project</v-card-title>
						<v-card-text>
							<div class="v-data-table bt-table theme--light">
								<div class="v-data-table__wrapper">
									<table class="mt-2" style="width: 100%">
										<thead>
											<tr>
												<!-- <th
													class="fw-700 px-3 py-2 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('id')"
												>
													#
													<v-icon small>
														{{
															sortBy === "id" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th> -->
												<th
													class="fw-700 px-3 py-2 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('camp')"
												>
													Name
													<v-icon small>
														{{
															sortBy === "camp" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th
													class="fw-700 px-3 py-1 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('onPage')"
												>
													On Page
													<v-icon small>
														{{
															sortBy === "onPage"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th class="fw-700 px-3 py-1 sortable" @click="sortTable('offPage')">
													Off Page
													<v-icon small>
														{{
															sortBy === "offPage"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(member, index) in sortedMembers" :key="index" class="promised-table-tr">
												<!-- <td class="fw-600 px-3 py-2 table_border">
													{{ member.id }}
												</td> -->
												<td class="fw-600 px-3 py-2 table_border">
													{{ member.camp }}
												</td>
												<td class="fw-500 px-3 py-1 table_border">
													<v-avatar color="green" size="27">
														<span class="white--text">{{ member.offPage }}</span>
													</v-avatar>
												</td>
												<td class="fw-500 px-3 py-1">
													<v-avatar color="red" size="27">
														<span class="white--text">{{ member.onPage }}</span>
													</v-avatar>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col md="3">
					<v-card outlined>
						<v-card-title class="pb-0 pt-3 fw-600">Lowest Worked Project</v-card-title>
						<v-card-text>
							<div class="v-data-table bt-table theme--light">
								<div class="v-data-table__wrapper">
									<table class="mt-2" style="width: 100%">
										<thead>
											<tr>
												<!-- <th
													class="fw-700 px-3 py-2 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('id')"
												>
													#
													<v-icon small>
														{{
															sortBy === "id" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th> -->
												<th
													class="fw-700 px-3 py-2 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('camp')"
												>
													Name
													<v-icon small>
														{{
															sortBy === "camp" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th
													class="fw-700 px-3 py-1 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('onPage')"
												>
													On Page
													<v-icon small>
														{{
															sortBy === "onPage"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th class="fw-700 px-3 py-1 sortable" @click="sortTable('offPage')">
													Off Page
													<v-icon small>
														{{
															sortBy === "offPage"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(member, index) in sortedMembers" :key="index" class="promised-table-tr">
												<!-- <td class="fw-600 px-3 py-2 table_border">
													{{ member.id }}
												</td> -->
												<td class="fw-600 px-3 py-2 table_border">
													{{ member.camp }}
												</td>
												<td class="fw-500 px-3 py-1 table_border">
													<v-avatar color="green" size="27">
														<span class="white--text">{{ member.offPage }}</span>
													</v-avatar>
												</td>
												<td class="fw-500 px-3 py-1">
													<v-avatar color="red" size="27">
														<span class="white--text">{{ member.onPage }}</span>
													</v-avatar>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col md="6">
					<v-card outlined>
						<v-card-title class="pb-0 pt-3 fw-600">Total Activity Done</v-card-title>
						<v-card-text>
							<div class="team-preview">
								<div class="v-data-table bt-table theme--light">
									<div class="v-data-table__wrapper" style="max-height: 193px; height: 193px">
										<table class="mt-2" style="width: 100%">
											<thead>
												<tr>
													<th
														class="fw-600 px-3 py-2 sortable"
														style="border-right: 0.5px solid rgb(177, 176, 176)"
														@click="sortTable('id')"
													>
														#
														<v-icon small>
															{{
																sortBy === "id" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
															}}
														</v-icon>
													</th>
													<th
														class="fw-600 px-3 py-2 sortable"
														style="border-right: 0.5px solid rgb(177, 176, 176)"
														@click="sortTable('camp')"
													>
														Projects
														<v-icon small>
															{{
																sortBy === "camp"
																	? sortDesc
																		? "mdi-arrow-down"
																		: "mdi-arrow-up"
																	: "mdi-arrow-down"
															}}
														</v-icon>
													</th>
													<th
														class="fw-500 px-3 py-1 sortable"
														style="border-right: 0.5px solid rgb(177, 176, 176)"
														@click="sortTable('t_activity')"
													>
														Total Avtiviy
														<v-icon small>
															{{
																sortBy === "t_activity"
																	? sortDesc
																		? "mdi-arrow-down"
																		: "mdi-arrow-up"
																	: "mdi-arrow-down"
															}}
														</v-icon>
													</th>
													<th class="fw-500 px-3 py-1 sortable" @click="sortTable('onPage')">
														On Page
														<v-icon small>
															{{
																sortBy === "onPage"
																	? sortDesc
																		? "mdi-arrow-down"
																		: "mdi-arrow-up"
																	: "mdi-arrow-down"
															}}
														</v-icon>
													</th>
													<th class="fw-500 px-3 py-1 sortable" @click="sortTable('offPage')">
														Off Page
														<v-icon small>
															{{
																sortBy === "offPage"
																	? sortDesc
																		? "mdi-arrow-down"
																		: "mdi-arrow-up"
																	: "mdi-arrow-down"
															}}
														</v-icon>
													</th>
													<th class="fw-500 px-3 py-1 sortable" @click="sortTable('completed_task')">
														Completed Tasks
														<v-icon small>
															{{
																sortBy === "completed_task"
																	? sortDesc
																		? "mdi-arrow-down"
																		: "mdi-arrow-up"
																	: "mdi-arrow-down"
															}}
														</v-icon>
													</th>
												</tr>
											</thead>
											<tbody>
												<tr
													v-for="(member, index) in totalActivityProject"
													:key="index"
													class="promised-table-tr"
												>
													<td class="fw-600 px-3 py-2 table_border">
														{{ member.id }}
													</td>
													<td class="fw-600 px-3 py-2 table_border">
														{{ member.proj }}
													</td>
													<td class="fw-500 px-3 py-1 table_border">
														<v-avatar color="#0D47A1" size="27">
															<span v-if="member.t_activity" class="white--text">{{ member.t_activity }}</span>
															<span v-else class="white--text">{{ member.t_activity }}</span>
														</v-avatar>
													</td>
													<td class="fw-500 px-3 py-1 table_border">
														<v-avatar color="green" size="27">
															<span class="white--text">{{ member.onPage }}</span>
														</v-avatar>
													</td>
													<td class="fw-500 px-3 py-1 table_border">
														<v-avatar color="red" size="27">
															<span class="white--text">{{ member.offPage }}</span>
														</v-avatar>
													</td>
													<td class="fw-500 px-3 py-1">
														<v-avatar color="green" size="27">
															<span class="white--text">{{ member.completed_task }}</span>
														</v-avatar>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row class="mt-1">
				<v-col md="12">
					<div id="renew-history" class="form-grp-title mb-0 fw-600 h3">
						#<a href="#renew-history"> Renew History </a>
					</div>
				</v-col>

				<v-col md="4">
					<v-card outlined>
						<v-card-title class="pb-0 pt-3 fw-600">SEO</v-card-title>
						<v-card-text>
							<div class="v-data-table bt-table theme--light">
								<div class="v-data-table__wrapper">
									<table class="mt-2" style="width: 100%">
										<thead>
											<tr>
												<th
													class="fw-700 px-3 py-2 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('id')"
												>
													#
													<v-icon small>
														{{
															sortBy === "id" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th
													class="fw-700 px-3 py-2 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('duration')"
												>
													Duration
													<v-icon small>
														{{
															sortBy === "duration"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th
													class="fw-700 px-3 py-1 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('renewal')"
												>
													Renewal
													<v-icon small>
														{{
															sortBy === "renewal"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="ren in sortedSeoRenew" :key="ren.id" class="promised-table-tr">
												<td class="fw-600 px-3 py-2 table_border">
													{{ ren.id }}
												</td>
												<td class="fw-600 px-3 py-2 table_border">
													{{ ren.duration }}
												</td>
												<td class="fw-500 px-3 py-1 table_border">
													<v-avatar color="green" size="27">
														<span class="white--text">{{ ren.renewal }}</span>
													</v-avatar>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col md="4">
					<v-card outlined>
						<v-card-title class="pb-0 pt-3 fw-600">Domain</v-card-title>
						<v-card-text>
							<div class="v-data-table bt-table theme--light">
								<div class="v-data-table__wrapper">
									<table class="mt-2" style="width: 100%">
										<thead>
											<tr>
												<th
													class="fw-700 px-3 py-2 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('id')"
												>
													#
													<v-icon small>
														{{
															sortBy === "id" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th
													class="fw-700 px-3 py-2 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('duration')"
												>
													Duration
													<v-icon small>
														{{
															sortBy === "duration"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th
													class="fw-700 px-3 py-1 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('renewal')"
												>
													Renewal
													<v-icon small>
														{{
															sortBy === "renewal"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="ren in sortedDomainRenew" :key="ren.id" class="promised-table-tr">
												<td class="fw-600 px-3 py-2 table_border">
													{{ ren.id }}
												</td>
												<td class="fw-600 px-3 py-2 table_border">
													{{ ren.duration }}
												</td>
												<td class="fw-500 px-3 py-1 table_border">
													<v-avatar color="green" size="27">
														<span class="white--text">{{ ren.renewal }}</span>
													</v-avatar>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>

				<v-col md="4">
					<v-card outlined>
						<v-card-title class="pb-0 pt-3 fw-600">SSL</v-card-title>
						<v-card-text>
							<div class="v-data-table bt-table theme--light">
								<div class="v-data-table__wrapper">
									<table class="mt-2" style="width: 100%">
										<thead>
											<tr>
												<th
													class="fw-700 px-3 py-2 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('id')"
												>
													#
													<v-icon small>
														{{
															sortBy === "id" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th
													class="fw-700 px-3 py-2 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('duration')"
												>
													Duration
													<v-icon small>
														{{
															sortBy === "duration"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th
													class="fw-700 px-3 py-1 sortable"
													style="border-right: 0.5px solid rgb(177, 176, 176)"
													@click="sortTable('renewal')"
												>
													Renewal
													<v-icon small>
														{{
															sortBy === "renewal"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="ren in sortedSslRenew" :key="ren.id" class="promised-table-tr">
												<td class="fw-600 px-3 py-2 table_border">
													{{ ren.id }}
												</td>
												<td class="fw-600 px-3 py-2 table_border">
													{{ ren.duration }}
												</td>
												<td class="fw-500 px-3 py-1 table_border">
													<v-avatar color="green" size="27">
														<span class="white--text">{{ ren.renewal }}</span>
													</v-avatar>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col md="12">
					<div id="category" class="form-grp-title mb-0 fw-600 h3">
						#<a href="#category"> Category & Backlinks </a>
					</div>
				</v-col>
				<v-col md="4">
					<v-card outlined>
						<v-card-title class="pb-0 pt-3 fw-600">Top 5 Industry Category (Average Rank)</v-card-title>
						<v-card-text>
							<div class="v-data-table bt-table theme--light">
								<div class="v-data-table__wrapper">
									<table class="mt-2" style="width: 100%">
										<thead>
											<tr>
												<th class="fw-600 px-3 py-2 sortable table_border" @click="sortTable('id')">
													#
													<v-icon small>
														{{
															sortBy === "id" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th class="fw-600 px-3 py-2 sortable table_border" @click="sortTable('proj')">
													Category
													<v-icon small>
														{{
															sortBy === "proj" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th class="fw-500 px-3 py-1 sortable table_border" @click="sortTable('current_rank')">
													Avg Ranking
													<v-icon small>
														{{
															sortBy === "current_rank"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(topProjects, index) in topSortedMembers"
												:key="index"
												class="promised-table-tr"
											>
												<td class="fw-600 px-3 py-2 table_border">
													{{ topProjects.id }}
												</td>
												<td class="fw-600 px-3 py-2 table_border">
													{{ topProjects.proj }}
												</td>
												<td class="fw-500 px-3 py-1 table_border">
													<v-avatar color="red" size="27">
														<span class="white--text">{{ topProjects.current_range }}</span>
													</v-avatar>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col md="4">
					<v-card outlined>
						<v-card-title class="pb-0 pt-3 fw-600">Worst 5 Industry Category (Average Rank)</v-card-title>
						<v-card-text>
							<div class="v-data-table bt-table theme--light">
								<div class="v-data-table__wrapper">
									<table class="mt-2" style="width: 100%">
										<thead>
											<tr>
												<th class="fw-600 px-3 py-2 sortable table_border" @click="sortTable('id')">
													#
													<v-icon small>
														{{
															sortBy === "id" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th class="fw-600 px-3 py-2 sortable table_border" @click="sortTable('proj')">
													Category
													<v-icon small>
														{{
															sortBy === "proj" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th class="fw-500 px-3 py-1 sortable table_border" @click="sortTable('current_rank')">
													Avg Ranking
													<v-icon small>
														{{
															sortBy === "current_rank"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(topProjects, index) in topSortedMembers"
												:key="index"
												class="promised-table-tr"
											>
												<td class="fw-600 px-3 py-2 table_border">
													{{ topProjects.id }}
												</td>
												<td class="fw-600 px-3 py-2 table_border">
													{{ topProjects.proj }}
												</td>
												<td class="fw-500 px-3 py-1 table_border">
													<v-avatar color="red" size="27">
														<span class="white--text">{{ topProjects.current_range }}</span>
													</v-avatar>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col md="4">
					<v-card outlined>
						<v-card-title class="pb-0 pt-3 fw-600">Backlinks</v-card-title>
						<v-card-text>
							<div class="v-data-table bt-table theme--light">
								<div class="v-data-table__wrapper">
									<table class="mt-2" style="width: 100%">
										<thead>
											<tr>
												<th class="fw-600 px-3 py-2 sortable table_border">Time</th>
												<th class="fw-500 px-3 py-1 sortable table_border">Backlinks</th>
											</tr>
										</thead>
										<tbody>
											<tr class="promised-table-tr">
												<td class="fw-600 px-3 py-2 table_border">Today</td>
												<td class="fw-500 px-3 py-1 table_border">1</td>
											</tr>
											<tr class="promised-table-tr">
												<td class="fw-600 px-3 py-2 table_border">Yesterday</td>
												<td class="fw-500 px-3 py-1 table_border">10</td>
											</tr>
											<tr class="promised-table-tr">
												<td class="fw-600 px-3 py-2 table_border">This Week</td>
												<td class="fw-500 px-3 py-1 table_border">20</td>
											</tr>
											<tr class="promised-table-tr">
												<td class="fw-600 px-3 py-2 table_border">Last Week</td>
												<td class="fw-500 px-3 py-1 table_border">30</td>
											</tr>
											<tr class="promised-table-tr">
												<td class="fw-600 px-3 py-2 table_border">Last Month</td>
												<td class="fw-500 px-3 py-1 table_border">30</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<v-row>
				<v-col md="12">
					<div id="bestNworse" class="form-grp-title mb-0 fw-600 h3">
						#<a href="#bestNworse"> Best & Worst </a>
					</div>
				</v-col>
				<v-col md="6">
					<v-card outlined>
						<v-card-title class="pb-0 pt-3 fw-600">Best 5 Projects</v-card-title>
						<v-card-text>
							<div class="v-data-table bt-table theme--light">
								<div class="v-data-table__wrapper">
									<table class="mt-2" style="width: 100%">
										<thead>
											<tr>
												<th class="fw-600 px-3 py-2 sortable table_border" @click="sortTable('proj')">
													Domain
													<v-icon small>
														{{
															sortBy === "proj" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th class="fw-500 px-3 py-1 sortable table_border" @click="sortTable('current_rank')">
													KPI
													<v-icon small>
														{{
															sortBy === "current_rank"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th class="fw-500 px-3 py-1 sortable table_border" @click="sortTable('last_rank')">
													Avg Rank
													<v-icon small>
														{{
															sortBy === "last_rank"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th class="fw-500 px-3 py-1 sortable" @click="sortTable('jump')">
													Months ahead
													<v-icon small>
														{{
															sortBy === "jump" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(topProjects, index) in topSortedMembers"
												:key="index"
												class="promised-table-tr"
											>
												<td class="fw-600 px-3 py-2 table_border">
													{{ topProjects.proj }}
												</td>
												<td class="fw-500 px-3 py-1 table_border">
													<v-avatar color="red" size="27">
														<span class="white--text">{{ topProjects.current_range }}</span>
													</v-avatar>
												</td>
												<td class="fw-500 px-3 py-1 table_border">
													<v-avatar color="green" size="27">
														<span v-if="topProjects.last_rank" class="white--text">{{
															topProjects.last_rank
														}}</span>
														<span v-else class="text-white">100</span>
													</v-avatar>
												</td>
												<td class="fw-500 px-3 py-1">
													<v-chip color="red" class="text-white">
														{{ `- ${topProjects.jump}` }}<v-icon>expand_more</v-icon>
													</v-chip>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col md="6">
					<v-card outlined>
						<v-card-title class="pb-0 pt-3 fw-600">Worst 5 Projects</v-card-title>
						<v-card-text>
							<div class="v-data-table bt-table theme--light">
								<div class="v-data-table__wrapper">
									<table class="mt-2" style="width: 100%">
										<thead>
											<tr>
												<th class="fw-600 px-3 py-2 sortable table_border" @click="sortTable('proj')">
													Domain
													<v-icon small>
														{{
															sortBy === "proj" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th class="fw-500 px-3 py-1 sortable table_border" @click="sortTable('current_rank')">
													KPI
													<v-icon small>
														{{
															sortBy === "current_rank"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th class="fw-500 px-3 py-1 sortable table_border" @click="sortTable('last_rank')">
													AVG Rank
													<v-icon small>
														{{
															sortBy === "last_rank"
																? sortDesc
																	? "mdi-arrow-down"
																	: "mdi-arrow-up"
																: "mdi-arrow-down"
														}}
													</v-icon>
												</th>
												<th class="fw-500 px-3 py-1 sortable" @click="sortTable('jump')">
													Months ahead
													<v-icon small>
														{{
															sortBy === "jump" ? (sortDesc ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-down"
														}}
													</v-icon>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr
												v-for="(topProjects, index) in topSortedMembers"
												:key="index"
												class="promised-table-tr"
											>
												<td class="fw-600 px-3 py-2 table_border">
													{{ topProjects.proj }}
												</td>
												<td class="fw-500 px-3 py-1 table_border">
													<v-avatar color="red" size="27">
														<span class="white--text">{{ topProjects.current_range }}</span>
													</v-avatar>
												</td>
												<td class="fw-500 px-3 py-1 table_border">
													<v-avatar color="green" size="27">
														<span v-if="topProjects.last_rank" class="white--text">{{
															topProjects.last_rank
														}}</span>
														<span v-else class="text-white">100</span>
													</v-avatar>
												</td>
												<td class="fw-500 px-3 py-1">
													<v-chip color="red" class="text-white">
														{{ `- ${topProjects.jump}` }}<v-icon>expand_more</v-icon>
													</v-chip>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>

			<MemberGraphs></MemberGraphs>
			<!-- <ProjectReportVue></ProjectReportVue> -->
			<UpdateDomain
				v-if="domainDialog"
				:updated-dialog="domainDialog"
				v-on:close="domainDialog = false"
				v-on:success="domainDialog = false"
			>
			</UpdateDomain>
			<!-- </v-sheet> -->
			<!-- </v-sheet> -->
		</div>
	</div>
	<!-- <v-pagination class="listing-pagination" :length="3" v-model="page"> </v-pagination> -->
</template>
<script>
import ImageTemplate from "@/view/components/Image";
import DateRangePicker from "@/view/components/DateRangePicker";
import MemberGraphs from "@/view/module/seoReport/MemberGraph";
import MomentJS from "moment-timezone";
import UpdateDomain from "@/view/module/domain/UpdateDomain.vue";
// import ProjectReportVue from "@/view/module/seoReporting/ProjectReport";
export default {
	name: "SeoExecutive",
	data() {
		return {
			dataTab: "seo_executive",
			page: 1,
			domainDialog: false,
			checkbox: false,
			billingList: [],
			billing: "",
			project: "",
			status: "all",
			entity: "",
			domain: {
				remark:
					"Lorem ipsum dolor  elit..Lorem ipsum dolor  elit..Lorem ipsum dolor  elit..Lorem ipsum dolor  elit..Lorem ipsum dolor  elit..Lorem ipsum dolor  elit..Lorem ipsum dolor  elit..",
			},
			date_range: [],
			domain_status_list: [
				{
					id: 192,
					text: "All Members",
					value: "all",
					type: 40,
					status_color: "blue",
					count: 0,
					status_count: 0,
				},
				{
					id: 194,
					text: "Sagar",
					value: "sagar",
					type: 40,
					status_color: "green",
					count: 0,
					status_count: 0,
				},
				{
					id: 193,
					text: "Gaurav",
					value: "gaurav",
					type: 40,
					status_color: "red",
					count: 0,
					status_count: 0,
				},
				{
					id: 194,
					text: "Mark Sen",
					value: "mark-sen",
					type: 40,
					status_color: "orange",
					count: 0,
					status_count: 0,
				},
			],

			members_list: {
				highest_project: [
					{
						id: 1,
						camp: "Bthrust",
						onPage: 5,
						offPage: 12,
					},
					{
						id: 2,
						camp: "TT",
						onPage: 4,
						offPage: 10,
					},
					{
						id: 3,
						camp: "Genic",
						onPage: 6,
						offPage: 4,
					},
				],
				topPerformaing_projects: [
					{
						id: 1,
						proj: "H1",
						last_rank: 40,
						current_range: 20,
						jump: 10,
					},
					{
						id: 2,
						proj: "H2",
						last_rank: "30",
						current_range: 22,
						jump: 11,
					},
					{
						id: 3,
						proj: "H3",
						last_rank: 50,
						current_range: 26,
						jump: 11,
					},
					{
						id: 4,
						proj: "H4",
						last_rank: 50,
						current_range: 26,
						jump: 11,
					},
					{
						id: 5,
						proj: "H5",
						last_rank: 50,
						current_range: 26,
						jump: 11,
					},
				],
				totalActivity_project: [
					{
						id: 1,
						proj: "PRT H1",
						t_activity: 40,
						onPage: 20,
						offPage: 10,
						completed_task: 25,
					},
					{
						id: 2,
						proj: "PRT H2",
						t_activity: 40,
						onPage: 20,
						offPage: 10,
						completed_task: 25,
					},
					{
						id: 3,
						proj: "PRT H3",
						t_activity: 40,
						onPage: 20,
						offPage: 10,
						completed_task: 25,
					},
					{
						id: 1,
						proj: "PRT H1",
						t_activity: 40,
						onPage: 20,
						offPage: 10,
						completed_task: 25,
					},
					{
						id: 2,
						proj: "PRT H2",
						t_activity: 40,
						onPage: 20,
						offPage: 10,
						completed_task: 25,
					},
					{
						id: 3,
						proj: "PRT H3",
						t_activity: 40,
						onPage: 20,
						offPage: 10,
						completed_task: 25,
					},
					{
						id: 1,
						proj: "PRT H1",
						t_activity: 40,
						onPage: 20,
						offPage: 10,
						completed_task: 25,
					},
					{
						id: 2,
						proj: "PRT H2",
						t_activity: 40,
						onPage: 20,
						offPage: 10,
						completed_task: 25,
					},
					{
						id: 3,
						proj: "PRT H3",
						t_activity: 40,
						onPage: 20,
						offPage: 10,
						completed_task: 25,
					},
					{
						id: 1,
						proj: "PRT H1",
						t_activity: 40,
						onPage: 20,
						offPage: 10,
						completed_task: 25,
					},
					{
						id: 2,
						proj: "PRT H2",
						t_activity: 40,
						onPage: 20,
						offPage: 10,
						completed_task: 25,
					},
					{
						id: 3,
						proj: "PRT H3",
						t_activity: 40,
						onPage: 20,
						offPage: 10,
						completed_task: 25,
					},
				],
			},

			renew_list: {
				seo: [
					{
						id: 1,
						duration: "In 3 Month",
						renewal: 30,
					},
					{
						id: 2,
						duration: "In 2 Month",
						renewal: 20,
					},
					{
						id: 3,
						duration: "In 1 Month",
						renewal: 10,
					},
				],

				domain: [
					{
						id: 1,
						duration: "In 3 Month",
						renewal: 30,
					},
					{
						id: 2,
						duration: "In 2 Month",
						renewal: 20,
					},
					{
						id: 3,
						duration: "In 1 Month",
						renewal: 10,
					},
				],

				ssl: [
					{
						id: 1,
						duration: "In 3 Month",
						renewal: 30,
					},
					{
						id: 2,
						duration: "In 2 Month",
						renewal: 20,
					},
					{
						id: 3,
						duration: "In 1 Month",
						renewal: 10,
					},
				],
			},
			sortBy: "",
			sortDesc: false,
			size: 70,
			width: 8,
			rotate: 360,
			color: "green",
			currentPercentage: 1,
			targetPercentage: 75,
			duration: 1000,
			increment: 1,
			currentPercentageRed: 1,
			targetPercentageRed: 65,
		};
	},
	mounted() {
		this.startAnimation();
		this.startAnimationRed();
	},
	methods: {
		startAnimation() {
			const intervalId = setInterval(() => {
				if (this.currentPercentage < this.targetPercentage) {
					this.currentPercentage += this.increment;
				} else {
					clearInterval(intervalId);
				}
			}, this.duration / ((this.targetPercentage - this.currentPercentage) / this.increment));
		},
		startAnimationRed() {
			const intervalIdRed = setInterval(() => {
				if (this.currentPercentageRed < this.targetPercentageRed) {
					this.currentPercentageRed += this.increment;
				} else {
					clearInterval(intervalIdRed);
				}
			}, this.duration / ((this.targetPercentageRed - this.currentPercentageRed) / this.increment));
		},
		sortTable(column) {
			if (this.sortBy === column) {
				this.sortDesc = !this.sortDesc;
			} else {
				this.sortBy = column;
				this.sortDesc = false;
			}
		},
		getStatusColor(status) {
			if (status == "Expired") {
				return "red";
			} else if (status == "Expire In 1 Month") {
				return "orange";
			} else if (status == "Active") {
				return "green";
			}
		},
		date_difference(start, end) {
			let start_Date = MomentJS(start);
			let end_Date = MomentJS(end);
			let totalDays = end_Date.diff(start_Date, "days");
			return totalDays;
		},
	},
	computed: {
		sortedSeoRenew() {
			const sortedList = [...this.renew_list.seo];
			if (this.sortBy) {
				sortedList.sort((a, b) => {
					const aValue = a[this.sortBy];
					const bValue = b[this.sortBy];
					if (aValue < bValue) return this.sortDesc ? 1 : -1;
					if (aValue > bValue) return this.sortDesc ? -1 : 1;
					return 0;
				});
			}
			return sortedList;
		},
		sortedDomainRenew() {
			const sortedList = [...this.renew_list.domain];
			if (this.sortBy) {
				sortedList.sort((a, b) => {
					const aValue = a[this.sortBy];
					const bValue = b[this.sortBy];
					if (aValue < bValue) return this.sortDesc ? 1 : -1;
					if (aValue > bValue) return this.sortDesc ? -1 : 1;
					return 0;
				});
			}
			return sortedList;
		},
		sortedSslRenew() {
			const sortedList = [...this.renew_list.ssl];
			if (this.sortBy) {
				sortedList.sort((a, b) => {
					const aValue = a[this.sortBy];
					const bValue = b[this.sortBy];
					if (aValue < bValue) return this.sortDesc ? 1 : -1;
					if (aValue > bValue) return this.sortDesc ? -1 : 1;
					return 0;
				});
			}
			return sortedList;
		},
		sortedMembers() {
			const sortedList = [...this.members_list.highest_project];
			if (this.sortBy) {
				sortedList.sort((a, b) => {
					const aValue = a[this.sortBy];
					const bValue = b[this.sortBy];
					if (aValue < bValue) return this.sortDesc ? 1 : -1;
					if (aValue > bValue) return this.sortDesc ? -1 : 1;
					return 0;
				});
			}
			return sortedList;
		},
		topSortedMembers() {
			const sortedList = [...this.members_list.topPerformaing_projects];
			if (this.sortBy) {
				sortedList.sort((a, b) => {
					const aValue = a[this.sortBy];
					const bValue = b[this.sortBy];
					if (aValue < bValue) return this.sortDesc ? 1 : -1;
					if (aValue > bValue) return this.sortDesc ? -1 : 1;
					return 0;
				});
			}

			return sortedList;
		},
		totalActivityProject() {
			const sortedList = [...this.members_list.totalActivity_project];
			if (this.sortBy) {
				sortedList.sort((a, b) => {
					const aValue = a[this.sortBy];
					const bValue = b[this.sortBy];
					if (aValue < bValue) return this.sortDesc ? 1 : -1;
					if (aValue > bValue) return this.sortDesc ? -1 : 1;
					return 0;
				});
			}

			return sortedList;
		},
		taskStatusBorder: function () {
			return function name(status) {
				if (status == "Expired") {
					return "border:1px solid red";
				}
				if (status == "Active") {
					return "border:1px solid green";
				}
			};
		},
	},
	components: {
		DateRangePicker,
		ImageTemplate,
		UpdateDomain,
		MemberGraphs,
	},
};
</script>

<style>
.sortable {
	cursor: pointer;
}
.table_border {
	border-right: 0.5px solid rgb(216, 216, 216);
}
.promised-table-tr:nth-child(odd) {
	background-color: rgb(243, 243, 243);
}
.bt-table .v-data-table__wrapper {
	height: auto;
	overflow: auto;
}
</style>
